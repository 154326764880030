<template>
  <div>
    <div class="searchBox" :style="{ opacity: config.isConfigMode ? 0.5 : 1, pointerEvents: config.isConfigMode ? 'none' : 'auto' }">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker v-model="dataForm.takeGoodsTime" type="daterange" align="right" unlink-panels
                          range-separator="-" size="small" @change="getDataList" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                          end-placeholder="结束日期" :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable placeholder="请选择"
                     @change="getDataList()">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户联系人">
          <el-input v-model="dataForm.customerContactName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="签收状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已签收" value="1"></el-option>
            <el-option label="未签收" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回单状态" prop="status">
          <el-select class="item-choose" v-model="dataForm.receiptStatus" style="width: 100%;" filterable clearable placeholder="请选择">
            <el-option label="已寄出" value="2"></el-option>
            <el-option label="未寄出" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('order:inside:export')" @click="downLoad">导出</el-button>
        </el-form-item>
        <el-form-item style="text-align: right; display: flex; justify-content: flex-end; align-items: center;">
          <div class="operationList">
            <el-button size="small" @click="templateConfigurationMode(0)" type="warning">模板配置模式</el-button>
            <el-button v-if="newAuth('create:special')" size="small" @click="operatopnList('createZC')"
                       icon="el-icon-plus" type="primary">创建新订单
            </el-button>
            <el-button v-if="newAuth('order:inside:update')" size="small" @click="operatopnList('attention', 'edit')"
                       type="danger" :disabled="selectListArr.length ? false : true">维护注意事项 </el-button>
            <el-button v-if="newAuth('order:inside:audit')" size="small" @click="operatopnList('order')" type="success"
                       :disabled="orderExamineAble">订单审核</el-button>
            <el-button size="small" @click="receiptUpdate(1)" type="danger" :disabled="selectFeeData.length ? false : true">回单撤回</el-button>
            <el-button size="small" @click="receiptUpdate(2)" type="warning" :disabled="selectFeeData.length ? false : true">回单寄出</el-button>
            <el-button size="small" type="success" v-if="newAuth('order:receivable:update')" @click="openWriteOffPopup(1)" :disabled="!selectFeeData.length">签收单核销</el-button>
            <el-button size="small" type="success" v-if="newAuth('order:receivable:update')" @click="batchAudit(2)" :disabled="!selectFeeData.length">签收单反核销</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox"  :style="{ position: 'relative' }">
      <!-- 配置模式 -->
      <el-row v-if="config.isConfigMode" style="justify-self: center;padding: 10px 10px">
        <el-button size="mini" @click="config.isConfigMode = false" type="danger">取消配置模式</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(1)" type="success">保存模板</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(2)" type="warning">修改模板</el-button>
        <el-button v-no-more-click size="mini" @click="$refs.configTableRef.init(config.configTableId)" type="warning">所有模板</el-button>
        <el-popover placement="right" width="520" trigger="click">
          <div style="justify-self: center;">
            <el-button v-no-more-click @click="checkAllNodes(true)" size="mini">全选</el-button>
            <el-button v-no-more-click @click="checkAllNodes(false)" size="mini">全不选</el-button>
            <el-tooltip class="item" effect="dark" content="可拖拽必须先取消固定才允许拖拽排列,如果有固定固定按钮会显示为绿色" placement="top-start">
              <i class="el-icon-view el-icon--right" />
            </el-tooltip>
          </div>
          <br/>
          <el-tree :data="config.configDefaultColumn"
                   style="max-height: 200px;overflow-y: auto;"
                   default-expand-all
                   show-checkbox
                   draggable
                   node-key="prop"
                   ref="configTableListTree"
                   @check-change="handleCheckChange"
                   @node-drop="handleDrop"
                   :allow-drag="allowDrag"
                   :allow-drop="allowDrop">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-row :gutter="20">
                       <el-col :span="16">
                         <div class="grid-content bg-purple">
                           <el-input size="mini" placeholder="列头名" v-model="data.label"></el-input>
                         </div>
                       </el-col>
                       <el-col :span="8">
                         <div class="grid-content bg-purple">
                           <el-link :type="data.fixed && data.fixed === 'left' ? 'success' : 'info'" @click="data.fixed = 'left'" :underline="false" >固定左侧</el-link>
                           <el-link :type="data.fixed && data.fixed === 'right' ? 'success' : 'info'" @click="data.fixed = 'right'" :underline="false" >固定右侧</el-link>
                           <el-link :disabled="!data.fixed" :underline="false" @click="data.fixed = undefined" >取消固定</el-link>
                         </div>
                       </el-col>
                    </el-row>
                  </span>
          </el-tree>
          <el-button style="margin-left: 10px;" size="mini" slot="reference" icon="el-icon-s-tools" circle />
        </el-popover>
        <br/>
        <br/>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-input v-model="config.configModeForm.name" size="mini" placeholder="模板名称" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <el-input v-model="config.configModeForm.remark" size="mini" placeholder="备注" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-switch
                  v-model="config.configModeForm.defaultOrNot"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="'1'"
                  :inactive-value="'0'">
              </el-switch>
            </div>
          </el-col>
        </el-row>
      </el-row>

      <el-table :data="dataList" :fit="true" border size="small" height="66vh" :row-class-name="tableRowClassName"
        @row-click="rowClick" :row-style="selectedHighlight" :span-method="arraySpanMethod" ref="tableRef"
        v-loading="dataListLoading" style="width: 100%;" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="40" fixed="left" align="center"></el-table-column>
        <el-table-column
            v-for="(item, index) in config.configDefaultColumn"
            :key="index"
            align="left"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :fixed="item.fixed"
            :show-overflow-tooltip="true"
            v-if="item.check"
        >
          <template slot-scope="scope">
            <!-- 注意事项 -->
            <template v-if="item.prop == 'takeRemark'">
            <span style="color:blue;cursor:pointer" @click="viewAttention(scope.row)"
                  v-if="scope.row.takeRemark">查看</span>
              <span v-else>无</span>
            </template>
            <!-- 货物尺寸(长/宽/高) -->
            <template v-else-if="item.prop == 'orderGoods.length'">
              {{ scope.row.orderGoods.length }} / {{ scope.row.orderGoods.width }} / {{ scope.row.orderGoods.height }}
            </template>
            <!-- 状态 -->
            <template v-else-if="item.prop == 'status'">
              <el-tag
                  v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
                  size="small" type="danger">未提交</el-tag>
              <el-tag
                  v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
                  size="small" type="warning">待审核</el-tag>
              <el-tag
                  v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
                  size="small" type="warning">调度中</el-tag>
              <el-tag
                  v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
                  size="small">已调度</el-tag>
              <el-tag
                  v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
                  size="small" type="success">已完成</el-tag>
              <el-tag
                  v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
                  size="small" type="danger">异常结束</el-tag>
              <el-tag
                  v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
                  size="small" type="danger">审核拒绝</el-tag>
              <el-tag
                  v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
                  size="small" type="danger">订单退回</el-tag>
              <el-tag
                  v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
                  size="small" type="info">已取消</el-tag>
              <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
              <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
              <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
              <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
              <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
            </template>
            <!-- 签收单状态 -->
            <template v-else-if="item.prop == 'orderInfo.writeStatus'">
              <el-tag v-if="scope.row.orderInfo.writeStatus == 1" size="small" type="success">已签收</el-tag>
              <el-tag v-if="scope.row.orderInfo.writeStatus == 2" size="small" type="danger">未签收</el-tag>
            </template>
            <!-- 回单状态 -->
            <template v-else-if="item.prop == '回单状态'">
              <el-tag v-if="scope.row.orderInfo.receiptStatus == 2" size="small" type="success">已寄出</el-tag>
              <el-tag v-else size="small" type="danger">未寄出</el-tag>
            </template>
            <!-- 司机名片分享微信 -->
            <template v-else-if="item.prop == 'driverBusinessCardSharingWechat'">
              <el-button @click="pasteAndCopy(scope.row,dataList)" :disabled="!scope.row.vehiclePlan" size="small" type="text">分享</el-button>
            </template>
            <template v-else>
              {{ getFormatter(item.formatter)(scope.row, item, _.get(scope.row, item.prop)) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="left" label="操作" width="40">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row, 'view')">详情 </el-button>
              <el-button
                v-if="scope.row.orderInfo.status != 8 && scope.row.orderInfo.status != 9 && newAuth('order:inside:update')"
                type="text" size="small" @click="addOrUpdateHandle(scope.row, 'edit')">修改 </el-button>
              <el-button v-if="scope.row.orderInfo.status == 8 && newAuth('order:inside:updateOrderNumber')" type="text"
                size="small" @click="updateOrderNumber(scope.row)">单号编辑</el-button>
              <el-button v-if="newAuth('order:inside:delete')" type="text" size="small"
                @click="deleteHandle(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[20, 50, 100]" :page-size="pageSize" :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <!-- 订单审核 -->
    <order-to-examine ref="orderToExamine" @refreshDataList="getDataList"></order-to-examine>
    <!-- 信息补录-->
    <info-supplement ref="infoSupplement" @refreshDataList="getDataList"></info-supplement>
    <!-- 订单退回 -->
    <order-return ref="orderReturn" @refreshDataList="getDataList"></order-return>
    <!-- 维护注意事项 -->
    <attention-pop ref="attention" @refreshDataList="getDataList"></attention-pop>
    <!-- 单号编辑 -->
    <order-number ref="orderNumber" @refreshDataList="getDataList"></order-number>

    <receipt-off-popup ref="receiptOff" @refreshDataList="getDataList"></receipt-off-popup>

    <!-- 模板配置弹窗 -->
    <configTable ref="configTableRef" />
  </div>
</template>

<script>
import configTable from "@/components/table";
import orderToExamine from './insideOrderPop/orderToExamine-popup'
import infoSupplement from './insideOrderPop/infoSupplement-popup'
import orderReturn from './insideOrderPop/orderReturnPop'
import attentionPop from './insideOrderPop/attentionPop'
import orderNumber from './insideOrderPop/orderNumber'
import qs from 'qs'
import receiptOffPopup from "@/views/modules/costManagement/detail/receipt-off-popup.vue";
import * as myJsonClon from "@/utils/myJsonClone"
export default {
  components: {
    configTable,
    receiptOffPopup,
    orderToExamine,
    infoSupplement,
    orderReturn,
    attentionPop,
    orderNumber
  },
  data() {
    return {
      // -----------------动态表格模式start-----------------
      config: {
        configId: null,
        configTableId: 4,
        configDefaultColumn: [
          {label: '分享',prop: 'driverBusinessCardSharingWechat',fixed: 'left',width: '40',check: true},
          {label: '客户名称',prop: 'orderInfo.customerName',width: '100',check: true,fixed: undefined},
          {label: '客户联系人',prop: 'orderInfo.customerContactName',width: '100',check: true,fixed: undefined},
          {label: '提货时间',prop: 'deliveryTimeNode',width: '105',check: true,fixed: undefined},
          {label: '进仓单号',prop: 'orderInfo.inWarehouseNo',width: '70',check: true,fixed: undefined},
          {label: '订单类型',prop: 'orderInfo.orderType',width: '60',formatter: 'orderType',check: true,fixed: undefined},
          {label: '所需车型',prop: 'orderInfo.vehicleType',width: '80',formatter: 'dictConvertVehicle',check: true,fixed: undefined},
          {label: '司机车牌',prop: 'vehiclePlan.vehiclePlate',width: '70',check: true,fixed: undefined},
          {label: '报关方式',prop: 'orderInfo.entranceType',width: '80',formatter: 'entranceType',check: true,fixed: undefined},
          {label: '提货方',prop: 'factoryName',width: '75',check: true,fixed: undefined},
          {label: '提货地址',prop: 'fullAddress',width: '180',check: true,fixed: undefined},
          {label: '提货联系人',prop: 'contactName',width: '70',check: true,fixed: undefined},
          {label: '提货电话',prop: 'contactPhone',width: '85',check: true,fixed: undefined},
          {label: '注意事项',prop: 'takeRemark',width: '60',check: true,fixed: undefined},
          {label: '卸货方',prop: 'giveAddressInfo.factoryName',width: '75"',check: true,fixed: undefined},
          {label: '收货地址',prop: 'giveAddressInfo.fullAddress',width: '180"',check: true,fixed: undefined},
          {label: '收货联系人',prop: 'giveAddressInfo.contactName',width: '70"',check: true,fixed: undefined},
          {label: '收货电话',prop: 'giveAddressInfo.contactPhone',width: '85"',check: true,fixed: undefined},
          {label: '公里数(KM)',prop: 'orderInfo.distance',width: '85"',formatter: 'getDistanceValue',check: true,fixed: undefined},
          {label: '件数',prop: 'orderGoods.pieceNum',width: '40"',check: true,fixed: undefined},
          {label: '体积',prop: 'orderGoods.volume',width: '40"',check: true,fixed: undefined},
          {label: '重量',prop: 'orderGoods.weight',width: '40"',check: true,fixed: undefined},
          {label: '货物尺寸(长/宽/高)',prop: 'orderGoods.length',width: '110"',check: true,fixed: undefined},
          {label: '包装类型',prop: 'orderGoods.packType',width: '60',formatter: 'manyDictConvert',check: true,fixed: undefined},
          {label: '货物备注',prop: 'orderGoods.remark',width: '110"',check: true,fixed: undefined},
          {label: '是否尾板',prop: 'orderInfo.isTailstock',width: '70"',formatter: 'yesNo',check: true,fixed: undefined},
          {label: '订单号',prop: 'orderInfo.orderNo',width: '105"',check: true,fixed: undefined},
          {label: '客户单号',prop: 'orderInfo.customerSelfNo',width: '70"',check: true,fixed: undefined},
          {label: '客户联系电话',prop: 'orderInfo.customerContactPhone',width: '90"',check: true,fixed: undefined},
          {label: '司机姓名',prop: 'vehiclePlan.driverName',width: '60"',check: true,fixed: undefined},
          {label: '司机电话',prop: 'vehiclePlan.driverPhone',width: '90"',check: true,fixed: undefined},
          {label: '下单时间',prop: 'orderInfo.commitTime',width: '120"',check: true,fixed: undefined},
          {label: '回单寄出时间',prop: 'orderInfo.receiptSendTime',width: '120"',check: true,fixed: undefined},
          {label: '回单收件人',prop: 'orderInfo.receiptAddressee',width: '70"',check: true,fixed: undefined},
          {label: '状态',prop: 'status',fixed: 'right',width: '70"',check: true},
          {label: '签收单状态',prop: 'orderInfo.writeStatus',fixed: 'right',width: '70"',check: true},
          {label: '回单状态',prop: '回单状态',fixed: 'right',width: '60"',check: true},
        ],
        configDefaultPropColumn: [],
        isConfigMode: false,
        configModeForm: {
          name: '',
          remark: '',
          defaultOrNot: '0'
        },
      },
      // -----------------动态表格模式end-----------------

      dataForm: {
        inWarehouseNo: null,
        customerSelfNo: null,
        customerName: null,
        takeGoodsTime: null,
        vehiclePlate: null,
        writeStatus: null,
        status: 1,
        customerIds: [],
        receiptStatus: null
      },
      statusList: [
        { id: 1, name: '待审核' },
        { id: 2, name: '调度中' },
        { id: 3, name: '已调度' },
        { id: 4, name: '已接单' },
        { id: 5, name: '已出车' },
        { id: 6, name: '已到达' },
        { id: 7, name: '已装货' },
        { id: 15, name: '卸货到场' },
        { id: 8, name: '已完成' },
        { id: 9, name: '异常结束' }
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectListArr: [],
      orderExamineAble: true,
      orderReturnAble: true,
      listType: 2,
      selectFeeData: [],
      getIndex: -1
    }
  },
  created() {
    this.initConfigTable()

    this.setDefaultDate()
  },
  activated() {
    if (!this.newAuth('order:inside:findAll')) { // 不查看所有的时候，判断所属客户
      this.dataForm.customerId = this.currentUser.customerId
      this.dataForm.customerIds = this.currentUser.customerIdList
    }
    this.getDataList()
  },
  computed: {
    currentUser: {
      get() { return this.$store.state.user.currentUser }
    },
    dictTypeMap: {
      get() {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    // -----------------动态表格模式start-----------------
    initConfigTable() {
      this.$http({
        url: this.$http.adornUrl('/tabulation/default/' + this.config.configTableId),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        if (data) {
          this.config.configModeForm.name = data.name
          this.config.configModeForm.remark = data.remark
          this.config.configModeForm.defaultOrNot = data.defaultOrNot
          this.config.configId = data.id
          if (data.tmsTabulationConfigValueList && data.tmsTabulationConfigValueList.length > 0) {
            this.assignPropertiesLarge(this.config.configDefaultColumn, data.tmsTabulationConfigValueList,'prop',['label','fixed','width'])
            this.config.configDefaultColumn = this.reorderArray(this.config.configDefaultColumn, data.tmsTabulationConfigValueList, 'prop');
          }
        }
        this.config.configDefaultColumn.forEach(x => {this.config.configDefaultPropColumn.push({prop: x.prop})})
      })

    },
    assignPropertiesLarge (arr1, arr2, compareProp, assignProps) {
      const map = new Map();
      arr2.forEach(item => {
        map.set(item[compareProp], item);
      });
      arr1.forEach(item => {
        const match = map.get(item[compareProp]);
        if (match) {
          assignProps.forEach(prop => {
            item[prop] = match[prop];
          });
          item.check = true
        } else {
          item.check = false
        }
      });
      return arr1;
    },
    reorderArray(arr1, arr2, key) {
      if (arr2.length === 0) {
        return arr1;
      }

      // 存储第二个数组中元素的特定属性值
      const targetValues = arr2.map(item => item[key]);
      // 存储匹配的元素
      const matched = [];
      // 存储未匹配的元素
      const unmatched = [];

      // 遍历第一个数组，将匹配的元素和未匹配的元素分别存储
      for (const item of arr1) {
        if (targetValues.includes(item[key])) {
          matched.push(item);
        } else {
          unmatched.push(item);
        }
      }

      // 对匹配的元素进行排序，使其顺序与第二个数组一致
      const sortedMatched = targetValues.map(value => {
        return matched.find(item => item[key] == value);
      }).filter(item => item!== undefined);
      // 合并匹配的元素和未匹配的元素
      return [...sortedMatched, ...unmatched];
    },
    templateConfigurationMode(type) {
      // 开启配置模式
      if (type === 0) {
        this.config.isConfigMode = true
        this.$nextTick(() => {
          const tree = this.$refs.configTableListTree;
          this.config.configDefaultColumn.forEach((node) => {
            if (node.check) {
              tree.setChecked(node.prop, true);
            }
          });
        })
      }
      // 保存/修改配置模式
      else if (type === 1 || type === 2) {
        // 检测
        if (!this.config.configModeForm.name) {
          this.$message.error('请输入配置模板名称!');
          return
        }
        if (type === 2) {

          if (!this.config.configId) {
            this.$message.error('未检测出有配置相关模板并启用,请新增!');
            return
          }
        }
        this.$nextTick(() => {
          let submitTable = myJsonClon.getClone(this.config.configModeForm)
          submitTable.tableId = this.config.configTableId
          submitTable.id = type === 2 ? this.config.configId : undefined
          const tableRef = this.$refs.tableRef;
          let tmsTabulationConfigValueList = []
          tableRef.columns.forEach((x,index) => {
            tmsTabulationConfigValueList.push({label: x.label,prop: x.property,fixed: x.fixed,width: x.width,sort: index})
          })
          submitTable.tmsTabulationConfigValueList = tmsTabulationConfigValueList;
          this.$http({
            url: this.$http.adornUrl(type === 1 ? '/tabulation/add' : '/tabulation/update'),
            method: type === 1 ? 'post' : 'put',
            data: submitTable
          }).then(async ({ data }) => {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
            })
            window.location.reload()
          })

        })
      }
    },
    getFormatter(formatterName) {
      if (formatterName) {
        // 首先尝试从组件的 methods 中获取方法
        let method = this[formatterName];
        if (typeof method === 'function') {
          return method;
        }
        // 若组件的 methods 中没有，再尝试从 Vue.prototype 中获取方法
        method = Vue.prototype[formatterName];
        if (typeof method === 'function') {
          return method;
        }
      }
      // 若都没找到，返回一个直接返回原始值的函数
      return (row, column, cellValue) => cellValue;
    },
    checkAllNodes (isChecked) {
      this.$nextTick(() => {
        if (isChecked) {
          this.$refs.configTableListTree.setCheckedNodes(this.config.configDefaultPropColumn)
        } else {
          this.$refs.configTableListTree.setCheckedKeys([])
          this.$forceUpdate()
        }
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const tree = this.$refs.configTableListTree;
      tree.setChecked(draggingNode.data.prop, draggingNode.data.check);
    },
    handleCheckChange(data, checked, indeterminate) {
      const targetElement = this.config.configDefaultColumn.find(item => item.prop === data.prop);
      targetElement.check = checked
    },
    allowDrag(draggingNode) {
      return draggingNode.data.fixed ? false : true
    },
    allowDrop(draggingNode, dropNode, type) {
      return type === 'inner' ? false : true
    },
    // -----------------动态表格模式end-----------------

    // dictConvertVehicle(row, column, cellValue) {
    //   // 处理 orderInfo.vehicleType
    //   return `${store.state.common.dictInfoMap[cellValue]} | ${row.orderInfo.vehicleModelName}`;
    // },
    /**
     * 点击表格变色start
     */
    selectedHighlight({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick(row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    setDefaultDate() {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    openWriteOffPopup(writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.orderInfo.writeStatus !== writeStatus) {
          ids.push(item.orderInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '签收单核销'
      if (writeStatus === 1) {
        title = '签收单核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/order/orderInfo/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    batchAudit(writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.orderInfo.writeStatus !== writeStatus) {
          ids.push(item.orderInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/order/orderInfo/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 多选
    handleSelectionChange(rows) {
      this.selectListArr = rows
      this.selectFeeData = rows
      let orderExamineFlag = false // 订单审核判断
      let orderReturnFlag = false //  订单退回判断
      rows.forEach(item => {
        // 订单审核需为未提交或待审核的订单
        if (item.orderInfo.status !== 0 && item.orderInfo.status !== 1) {
          orderExamineFlag = true
        }
        // 订单退回状态判断
        if (item.orderInfo.status > 4) {
          orderReturnFlag = true
        }
      })
      if (rows.length) {
        if (orderExamineFlag) {
          this.orderExamineAble = true
        } else {
          this.orderExamineAble = false
        }
        if (orderReturnFlag) {
          this.orderReturnAble = true
        } else {
          this.orderReturnAble = false
        }
      } else {
        this.orderExamineAble = true
        this.orderReturnAble = true
      }
    },
    // 查看注意事项
    viewAttention(row) {
      this.$refs.attention.init(row, 'view')
    },
    // 操作按钮
    operatopnList(type) {
      if (type === 'createPC') {
        // 创建拼车订单
        this.$router.push('/order-create-carpooling')
      } else if (type === 'createZC') {
        this.$router.push('/order-create-special')
        // 创建专车订单
      } else if (type === 'address') {
        // 地址反查
      } else if (type === 'info') {
        // 信息补录
        // this.$refs.orderToExamine.init(this.selectListArr)
      } else if (type === 'orderReturn') {
        // 订单退回
        this.$refs.orderReturn.init(this.selectListArr)
      } else if (type === 'attention') {
        // 维护注意事项
        if (this.selectListArr.length > 1) {
          this.$message.error('注意事项只能单个维护')
          return
        }
        this.$refs.attention.init(this.selectListArr[0], 'edit')
      } else if (type === 'order') {
        // 订单审核
        this.$refs.orderToExamine.init(this.selectListArr)
      } else if (type === 'printPaper') {
        // 打印落货纸
      } else if (type === 'printsheet') {
        // 打印派车单
      }
    },
    // 订单退回
    orderReturn(row) {
      this.$refs.orderReturn.init(row)
    },
    downLoad() {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/order/orderExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': 2,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'status': this.dataForm.status,
          'customerSelfNo': this.dataForm.customerSelfNo,
          'customerName': this.dataForm.customerName,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '订单列表(内部).xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'status': this.dataForm.status,
        'customerSelfNo': this.dataForm.customerSelfNo,
        'customerName': this.dataForm.customerName,
        'vehiclePlate': this.dataForm.vehiclePlate,
        'writeStatus': this.dataForm.writeStatus,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'customerIdList': this.dataForm.customerIds,
        'receiptStatus': this.dataForm.receiptStatus
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/order/findInsideList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 详情
    detail(id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle(row, todo) {
      this.$router.push({
        name: 'carpoolEditDetail',
        params: {
          id: row.orderInfo.id,
          todo: todo,
          listType: this.listType
        }
      })
    },
    // 删除
    deleteHandle(row) {
      this.$confirm(`确定对【${row.orderInfo.orderNo}】进行【删除】操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/order/delete/' + row.orderInfo.id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => { })
    },
    // 单号编辑
    updateOrderNumber(row) {
      this.$refs.orderNumber.init(row)
    },
    getDistanceValue(row, cell, value) {
      return parseFloat((value * 0.001).toFixed(1))
    },
    receiptUpdate (receiptStatus, row) {
      if (receiptStatus === 1) { // 回单撤销
        let ids = []
        if (row) {
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.orderInfo.id)
          })
        }
        this.$confirm('正在执行【回单撤销】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/order/receiptUpdate'),
            method: 'post',
            data: {
              ids: ids,
              receiptStatus: receiptStatus
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => { })
      } else if (receiptStatus === 2) { // 回单寄出
        //this.receiptOffVisible = true
        let ids = []
        this.selectFeeData.forEach(item => {
          if (item.orderInfo.receiptStatus !== receiptStatus) {
            ids.push(item.orderInfo.id)
          }
        })
        if (ids.length === 0) {
          this.$message.error('没有符合寄出的数据，请检查是否勾选正确')
          return
        }
        this.$refs.receiptOff.init(receiptStatus, ids)
      }
    },

    // 粘贴复制
    async pasteAndCopy(row,list) {
      if (row.vehiclePlan) {
        // 提货和送货
        let id = row.orderInfo.id;
        let rows = list.filter(x => {
          if (x.orderInfo && x.orderInfo.id == id) {
            return true
          } else {
            return false
          }
        })
        let rowsStr = ''
        rows.forEach(x => {
          rowsStr += '提货点: ' +  (x.cityNode ? x.cityNode : '') + (x.areaNode ? x.areaNode : '') + '\n' + '卸货点: ' + (x.giveAddressInfo.city ? x.giveAddressInfo.city : '') + (x.giveAddressInfo.area ? x.giveAddressInfo.area : '') + '\n-----------------------------\n'
        })

        // 尝试唤起微信
        try {
          window.location.href = `weixin:`
        } catch (e) {}

        // 复制剪贴板
        const formattedText = `深圳市瑞通达物流有限公司
姓名：${row.vehiclePlan.driverName}
电话:  ${row.vehiclePlan.driverPhone}
身份证：${row.vehiclePlan.driverCard}
车牌及车型：${row.vehiclePlan.vehiclePlate} / ${row.vehiclePlan.vehicleTypeName}
车重：${row.vehiclePlan.vehicleEmptyWeight ? row.vehiclePlan.vehicleEmptyWeight + 'KG' : ''}
${rowsStr} `;
        try {
          await navigator.clipboard.writeText(formattedText);
          this.$message.success('分享成功,已复制到粘贴板!')
        } catch (e) {
          this.$message.error('复制失败')
        }
      }
    }
  },
  watch: {
    '$store.state.common.audited': {
      handler: function (val, oldVal) {
        if (val === '新委派') {
          this.dataForm.status = 1
        }
        // if (val) {
        //   this.selectTab({ label: val })
        //   this.tabActive = val
        // } else {
        //   this.selectTab({ label: '全部' })
        //   this.tabActive = '全部'
        // }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
div#pane-order-list-inside {
  .el-dialog__header {
    padding: 10px;
    padding-top: 20px
  }

  .el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 5px 25px;
  }

  .el-dialog__footer {
    padding: 0px 20px 10px;
  }
}
</style>
